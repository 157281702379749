import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import withAsyncImport from '../components_util/hocs/util/AsyncImport';
import PublicRouting from './_variants/PublicRouting';
import ProtectedRouting from './_variants/ProtectedRouting';

//Templates
const DashboardTemplate = withAsyncImport(
  () => import('./../components_util/templates/DashboardTemplate/DashboardTemplate')
);
// const PublicTemplate = withAsyncImport(() => import('./../components_util/templates/PublicTemplate/PublicTemplate'));

//Util
const NotFound = withAsyncImport(() => import('./../views/App/NotFound/NotFound'));
const ErrorPage = withAsyncImport(() => import('./../views/App/ErrorPage/ErrorPage'));

//App routes
const Login = withAsyncImport(() => import('./../views/App/Login/Login'));
const Dashboard = withAsyncImport(() => import('../views/App/Dashboard/Dashboard'));
const Deployment = withAsyncImport(() => import('../views/App/Deployment/Deployment'));

//Tags
const TagCategories = withAsyncImport(() => import('./../views/Tags/TagCategories/TagCategories'));
const TagManagementPage = withAsyncImport(() => import('./../views/Tags/TagManagementPage/TagManagementPage'));
const BulkCreateTagsPage = withAsyncImport(() => import('./../views/Tags/BulkCreateTagsPage/BulkCreateTagPage'));

//Links
const LinksPage = withAsyncImport(() => import('./../views/Links/LinksPage/LinksPage'));

//Lists
const ListContainerPage = withAsyncImport(() => import('./../views/Lists/ListContainersPage/ListContainersPage'));
const ListContainerCreatePage = withAsyncImport(
  () => import('./../views/Lists/ListContainerCreatePage/ListContainerCreatePage')
);
const ListContainerBulkCreatePage = withAsyncImport(
  () => import('./../views/Lists/ListContainerBulkCreatePage/ListContainerBulkCreatePage')
);
const ListContainerEditPage = withAsyncImport(
  () => import('./../views/Lists/ListContainerEditPage/ListContainerEditPage')
);
const ListUpdateImportPage = withAsyncImport(
  () => import('./../views/Lists/ListUpdateImportPage/ListUpdateImportPage')
);

//Updates
const ListUpdatesPage = withAsyncImport(() => import('./../views/Lists/ListUpdatesPage/ListUpdatesPage'));
const ListUpdatePage = withAsyncImport(() => import('./../views/Lists/ListUpdatePage/ListUpdatePage'));

//Public
const DataSearchPage = withAsyncImport(() => import('../views/Public/DataSearchPage/DataSearchPage'));
const ProductPage = withAsyncImport(() => import('./../views/Public/ProductPage/ProductPage'));

//Purchase
// const PurchasePage = withAsyncImport(() => import('../views/Public/PurchasePage/PurchasePage'));
// const ThankYouPage = withAsyncImport(() => import('../views/Public/ThankYouPage/ThankYouPage'));

//Leads
const FreeListLeadsListPage = withAsyncImport(
  () => import('../views/Data/RequestedFreeListLeadsPage/RequestedFreeListLeadsPage')
);

interface AppRouterProps {}

const AppRouter: React.FunctionComponent<AppRouterProps> = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Private routes, accessible only when user is logged in  */}
        <Route element={<ProtectedRouting />}>
          <Route element={<DashboardTemplate />}>
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="search">
              <Route index element={<DataSearchPage />} />
              <Route path=":product_id" element={<ProductPage />} />
            </Route>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/deployment" element={<Deployment />} />
            <Route path="/tags">
              <Route index element={<TagManagementPage />} />
              <Route path="categories" element={<TagCategories />} />
              <Route path="bulk-create" element={<BulkCreateTagsPage />} />
            </Route>
            <Route path="/links" element={<LinksPage />} />
            <Route path="/lists">
              <Route index element={<ListContainerPage />} />
              <Route path="create" element={<ListContainerCreatePage />} />
              <Route path="bulk-create" element={<ListContainerBulkCreatePage />} />
              <Route path=":container_uuid">
                <Route index element={<ListUpdatesPage />} />
                <Route path="edit" element={<ListContainerEditPage />} />
                <Route path="update">
                  <Route index element={<ListUpdateImportPage />} />
                  <Route path=":update_uuid" element={<ListUpdatePage />} />
                </Route>
              </Route>
            </Route>
            <Route path="/leads/requested-free-list" element={<FreeListLeadsListPage />} />
          </Route>
        </Route>

        {/* Public routes, accessible only when logged out */}
        <Route element={<PublicRouting />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route path="*" element={<NotFound />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
