import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseError from "../../../shared/errors/base/BaseError";
import api from "../../../api";
import { isOfErrorType } from "../../../shared/type_guards/error";
import ServerError from "../../../shared/errors/ServerError";
import { IListUpdateDTO } from "../../../shared/dto/lists";

export const getListUpdatesThunk = createAsyncThunk<IListUpdateDTO[], { container_uuid: string }, { rejectValue: BaseError }>('listUpdate/getUpdates', async (args, thunkAPI) => {
    try {
        const updates = await api.LIST_UPDATE.get_list_updates(args.container_uuid);

        return updates.data;
    } catch (error) {
        if (isOfErrorType(error)) {
            return thunkAPI.rejectWithValue(error)
        } else {
            return thunkAPI.rejectWithValue(new ServerError())
        }
    }
})

export const getListUpdateThunk = createAsyncThunk<IListUpdateDTO, { container_uuid: string, update_uuid: string }, { rejectValue: BaseError }>('listUpdate/getUpdate', async (args, thunkAPI) => {
    try {
        const updates = await api.LIST_UPDATE.get_list_update(args.container_uuid, args.update_uuid);

        return updates.data;
    } catch (error) {
        if (isOfErrorType(error)) {
            return thunkAPI.rejectWithValue(error)
        } else {
            return thunkAPI.rejectWithValue(new ServerError())
        }
    }
})