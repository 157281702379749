import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserDTO } from "../../../shared/dto/user";
import { IThunkCallEvent } from "../../../types/redux";
import user_builder from "./builder";

export type IUserSliceState = {
    user?: IUserDTO | null;
    statuses: {
        fetch_user: IThunkCallEvent;
    }
};

const initial_state: IUserSliceState = {
    user: null,
    statuses: {
        fetch_user: { state: 'idle' }
    }

};

const user_slice = createSlice({
    name: 'user',
    initialState: initial_state,
    reducers: {
        setUser(state, action: PayloadAction<IUserDTO>) {
            return {
                ...state,
                user: action.payload,
            }
        }
    },
    extraReducers: user_builder
})

export default user_slice.reducer;
export const { setUser } = user_slice.actions;