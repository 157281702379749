import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ISystemSliceState } from "./slice";
import { getDefaultListImage } from "./thunks";

const system_builder = (builder: ActionReducerMapBuilder<ISystemSliceState>) => {
    builder.addCase(getDefaultListImage.fulfilled, (state, action) => {
        return {
            ...state,
            default_list_image: action.payload,
            statuses: {
                ...state.statuses,
                fetch_default_image: {
                    state: 'fulfilled',
                    error: undefined,
                }
            }
        }
    });
    builder.addCase(getDefaultListImage.pending, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_default_image: {
                    state: 'pending',
                }
            }
        }
    });
    builder.addCase(getDefaultListImage.rejected, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_default_image: {
                    state: 'rejected',
                    error: action.payload,
                }
            }
        }
    });
}

export default system_builder;