import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IListUpdateSliceState } from "./slice";
import { getListUpdateThunk, getListUpdatesThunk } from "./thunks";

const list_update_builder = (builder: ActionReducerMapBuilder<IListUpdateSliceState>) => {
    builder.addCase(getListUpdatesThunk.fulfilled, (state, action) => {
        return {
            ...state,
            list_updates: action.payload,
            statuses: {
                ...state.statuses,
                fetch_updates: {
                    state: 'fulfilled',
                    container_uuid: action.meta.arg.container_uuid,
                    error: undefined,
                }
            }
        }
    })
    builder.addCase(getListUpdatesThunk.pending, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_updates: {
                    state: 'pending',
                    container_uuid: action.meta.arg.container_uuid,
                    error: undefined
                }
            }
        }
    })
    builder.addCase(getListUpdatesThunk.rejected, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_updates: {
                    state: 'rejected',
                    container_uuid: action.meta.arg.container_uuid,
                    error: action.payload,
                }
            }
        }
    })


    builder.addCase(getListUpdateThunk.fulfilled, (state, action) => {
        return {
            ...state,
            list_update: action.payload,
            statuses: {
                ...state.statuses,
                fetch_update: {
                    state: 'fulfilled',
                    container_uuid: action.meta.arg.container_uuid,
                    update_uuid: action.meta.arg.update_uuid,
                    error: undefined,
                }
            }
        }
    })
    builder.addCase(getListUpdateThunk.pending, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_update: {
                    state: 'pending',
                    container_uuid: action.meta.arg.container_uuid,
                    update_uuid: action.meta.arg.update_uuid,
                    error: undefined
                }
            }
        }
    })
    builder.addCase(getListUpdateThunk.rejected, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_update: {
                    state: 'rejected',
                    container_uuid: action.meta.arg.container_uuid,
                    update_uuid: action.meta.arg.update_uuid,
                    error: action.payload,
                }
            }
        }
    })
}

export default list_update_builder;