import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ILeadsSliceState } from './slice';
import { getFreeListLeadsThunk } from './thunks';

const leads_builder = (builder: ActionReducerMapBuilder<ILeadsSliceState>) => {
  builder.addCase(getFreeListLeadsThunk.fulfilled, (state, action) => {
    return {
      ...state,
      freeListLeads: action.payload.rows,
      statuses: {
        ...state.statuses,
        fetchFreeListLeads: {
          ...state.statuses.fetchFreeListLeads,
          state: 'fulfilled',
          page: action.meta.arg.page,
          total: action.payload.count,
          error: undefined,
        },
      },
    };
  });
  builder.addCase(getFreeListLeadsThunk.pending, (state, action) => {
    return {
      ...state,
      statuses: {
        ...state.statuses,
        fetchFreeListLeads: {
          ...state.statuses.fetchFreeListLeads,
          state: 'pending',
        },
      },
    };
  });
  builder.addCase(getFreeListLeadsThunk.rejected, (state, action) => {
    return {
      ...state,
      statuses: {
        ...state.statuses,
        fetchFreeListLeads: {
          ...state.statuses.fetchFreeListLeads,
          state: 'rejected',
          error: action.payload,
        },
      },
    };
  });
};

export default leads_builder;
