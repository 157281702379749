import { createSlice } from "@reduxjs/toolkit";
import { IListUpdateDTO } from "../../../shared/dto/lists";
import { IThunkCallEvent } from "../../../types/redux";
import list_update_builder from "./builder";

export type IListUpdateSliceState = {
    list_update: IListUpdateDTO | null;
    list_updates: IListUpdateDTO[] | null;
    statuses: {
        fetch_update: IThunkCallEvent & { container_uuid?: string, update_uuid?: string },
        fetch_updates: IThunkCallEvent & { container_uuid?: string },
    }
}

const initial_state: IListUpdateSliceState = {
    list_update: null,
    list_updates: null,
    statuses: {
        fetch_update: {
            state: 'idle',
        },
        fetch_updates: {
            state: 'idle'
        }
    }
};

const list_update_slice = createSlice({
    name: "listUpdate",
    initialState: initial_state,
    reducers: {},
    extraReducers: list_update_builder,
})

export default list_update_slice.reducer;

export const { } = list_update_slice.actions;