import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICountedList } from '../../../shared/types/list';
import { IEmailNewsLetterDTO } from '../../../shared/dto/email';
import BaseError from '../../../shared/errors/base/BaseError';
import { isOfErrorType } from '../../../shared/type_guards/error';
import ServerError from '../../../shared/errors/ServerError';
import api from '../../../api';

export const getFreeListLeadsThunk = createAsyncThunk<
  ICountedList<IEmailNewsLetterDTO>,
  { page: number },
  { rejectValue: BaseError }
>('leads/getFreeListLeads', async (args, thunkAPI) => {
  try {
    const response = await api.LEADS.getFreeEmailLeads(args.page);

    return response.data;
  } catch (error) {
    if (isOfErrorType(error)) {
      return thunkAPI.rejectWithValue(error);
    } else {
      return thunkAPI.rejectWithValue(new ServerError());
    }
  }
});
