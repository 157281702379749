import { IDetailedProductDTO, IProductDTO } from "../../shared/dto/products";
import { ICountedList } from "../../shared/types/list";
import BaseAPI from "../base/base_api";


class ProductsAPI extends BaseAPI {
    private paths = {
        PRODUCTS: this.backend + '/api/products'
    };


    getProducts(filters?: { [key: string]: string }, page: number = 1) {
        return this.axiosInstance.get<ICountedList<IProductDTO>>(this.paths.PRODUCTS, {
            params: {
                ...filters,
                page
            },
        });
    }

    getProduct(uuid: string) {
        return this.axiosInstance.get<IDetailedProductDTO>(this.paths.PRODUCTS + "/" + uuid + "?include_related=true");
    }
}

export default ProductsAPI;