import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

interface PublicRoutingProps {}

const PublicRouting: React.FunctionComponent<PublicRoutingProps> = () => {
  const [token, setToken] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const at = localStorage.getItem('at');
    setToken(at);
  }, []);

  useEffect(() => {
    if (token) {
      navigate('/dashboard', { replace: true });
    }
  }, [token, navigate]);

  return <Outlet />;
};

export default PublicRouting;
