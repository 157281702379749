import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseError from "../../../shared/errors/base/BaseError";
import api from "../../../api";
import { isOfErrorType } from "../../../shared/type_guards/error";
import ServerError from "../../../shared/errors/ServerError";
import { IFilterDTO } from "../../../shared/dto/filters";

export const getFiltersThunk = createAsyncThunk<IFilterDTO[], {}, { rejectValue: BaseError }>('filters/getFilters', async (args, thunkAPI) => {
    try {
        const links_response = await api.FILTERS.get_filters();

        return links_response.data;
    } catch (error) {
        if (isOfErrorType(error)) {
            return thunkAPI.rejectWithValue(error)
        } else {
            return thunkAPI.rejectWithValue(new ServerError())
        }
    }
})