import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILinkDTO } from "../../../shared/dto/links";
import { IThunkCallEvent } from "../../../types/redux";
import links_builder from "./builder";

export type ILinksSliceState = {
    links: ILinkDTO[] | null
    statuses: {
        fetch_links: IThunkCallEvent
    }
}

const initial_state: ILinksSliceState = {
    links: null,
    statuses: {
        fetch_links: {
            state: 'idle'
        }
    }
};


const links_slice = createSlice({
    name: "links",
    initialState: initial_state,
    reducers: {
        addLink(state, action: PayloadAction<ILinkDTO>) {
            return {
                ...state,
                links: state.links ? [...state.links, action.payload] : null,
            }
        },
        updateLink(state, action: PayloadAction<ILinkDTO>) {
            const payload_link = action.payload;
            return {
                ...state,
                links: state.links ? state.links.map(v => {
                    if (v.uuid === payload_link.uuid) {
                        return payload_link;
                    } else {
                        return v
                    }
                }) : null
            }
        },
        removeLink(state, action: PayloadAction<string>) {
            const uuid = action.payload;
            return {
                ...state,
                links: state.links ? state.links.filter(v => v.uuid !== uuid) : null,
            }
        }
    },
    extraReducers: links_builder,
})

export default links_slice.reducer;

export const { addLink, updateLink, removeLink } = links_slice.actions;

