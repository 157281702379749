import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITagCategoryDTO, ITagDTO, } from "../../../shared/dto/tags";
import { IThunkCallEvent } from "../../../types/redux";
import tag_builder from "./builder";
import { IRoleDTO } from "../../../shared/dto/role";

export type ITagSliceState = {
    categories: ITagCategoryDTO[] | null;
    roles: IRoleDTO[] | null;
    statuses: {
        fetch_categories: IThunkCallEvent;
        fetch_roles: IThunkCallEvent;
    }
}

const initial_state: ITagSliceState = {
    categories: null,
    roles: null,
    statuses: {
        fetch_categories: {
            state: 'idle',
        },
        fetch_roles: {
            state: 'idle',
        }
    },
};

const tags_slice = createSlice({
    name: 'tags',
    initialState: initial_state,
    reducers: {
        addNewTagCategory(state, action: PayloadAction<ITagCategoryDTO>) {
            return {
                ...state,
                categories: [...state.categories || [], action.payload],
            }
        },
        removeTagCategory(state, action: PayloadAction<string>) {
            return {
                ...state,
                categories: state.categories ? state.categories.filter(v => {
                    return v.uuid !== action.payload
                }) : null
            }
        },
        editTagCategory(state, action: PayloadAction<ITagCategoryDTO>) {
            return {
                ...state,
                categories: state.categories ? state.categories.map(v => {
                    if (v.uuid === action.payload.uuid) {
                        return action.payload;
                    } else {
                        return v;
                    }
                }) : null
            }
        },
        addTag(state, action: PayloadAction<{ category_uuid: string, tag: ITagDTO }>) {
            const { category_uuid, tag } = action.payload;
            return {
                ...state,
                categories: state.categories ? state.categories.map(v => {
                    if (v.uuid === category_uuid) {
                        return {
                            ...v,
                            tags: [...v.tags, tag]
                        }
                    } else {
                        return v;
                    }
                }) : null
            }
        },
        removeTag(state, action: PayloadAction<{ category_uuid: string, tag_uuid: string }>) {
            const { category_uuid, tag_uuid } = action.payload;
            return {
                ...state,
                categories: state.categories ? state.categories.map(v => {
                    if (v.uuid === category_uuid) {
                        return {
                            ...v,
                            tags: v.tags.filter(o => o.uuid !== tag_uuid)
                        }
                    } else {
                        return v;
                    }
                }) : null
            }
        },
        updateTag(state, action: PayloadAction<{ category_uuid: string, tag: ITagDTO }>) {
            const { category_uuid, tag } = action.payload;
            return {
                ...state,
                categories: state.categories ? state.categories.map(v => {
                    if (v.uuid === category_uuid) {
                        return {
                            ...v,
                            tags: v.tags.map(o => {
                                if (o.uuid === tag.uuid) {
                                    return tag
                                } else {
                                    return o;
                                }
                            })
                        }
                    } else {
                        return v;
                    }
                }) : null
            }
        }
    },
    extraReducers: tag_builder,
})

export default tags_slice.reducer;
export const { addNewTagCategory, removeTagCategory, editTagCategory, addTag, removeTag, updateTag } = tags_slice.actions;