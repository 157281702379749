import { createAsyncThunk } from "@reduxjs/toolkit";
import { ITagCategoryDTO } from "../../../shared/dto/tags";
import BaseError from "../../../shared/errors/base/BaseError";
import api from "../../../api";
import { isOfErrorType } from "../../../shared/type_guards/error";
import ServerError from "../../../shared/errors/ServerError";
import { IRoleDTO } from "../../../shared/dto/role";

export const getTagRolesThunk = createAsyncThunk<IRoleDTO[], {}, { rejectValue: BaseError }>('tags/getTagRoles', async (args, thunkAPI) => {
    try {
        const tag_roles = await api.TAGS_API.get_roles();

        return tag_roles.data;
    } catch (error) {
        if (isOfErrorType(error)) {
            return thunkAPI.rejectWithValue(error)
        } else {
            return thunkAPI.rejectWithValue(new ServerError())
        }
    }
})

export const getTagCategoriesThunk = createAsyncThunk<ITagCategoryDTO[], {}, { rejectValue: BaseError }>('tags/getTagCategories', async (args, thunkAPI) => {
    try {
        const tags_response = await api.TAGS_API.get_categories();

        return tags_response.data
    } catch (error) {
        if (isOfErrorType(error)) {
            return thunkAPI.rejectWithValue(error)
        } else {
            return thunkAPI.rejectWithValue(new ServerError())
        }
    }
})
