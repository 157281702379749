import { ICheckoutDataDTO } from "../../shared/dto/checkout";
import BaseAPI from "../base/base_api";


class CheckoutAPI extends BaseAPI {
    private paths = {
        CHECKOUT: this.backend + '/api/checkout?container_uuid=:container_uuid&update_uuid=:update_uuid&internal=true',
        CHECKOUT_DATA: this.backend + "/api/checkout/:session_id"
    };

    get_product_checkout_session(container_uuid: string, update_uuid: string) {
        const url = this.replace_param(this.paths.CHECKOUT, [[':container_uuid', container_uuid], [':update_uuid', update_uuid]])

        return this.axiosInstance.get<string>(url);
    }

    get_checkout_data(session_id: string) {
        return this.axiosInstance.get<ICheckoutDataDTO>(this.replace_param(this.paths.CHECKOUT_DATA, [[':session_id', session_id]]));
    }
}

export default CheckoutAPI;