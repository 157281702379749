import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ITagSliceState } from "./slice";
import { getTagCategoriesThunk, getTagRolesThunk } from "./thunks";

const tag_builder = (builder: ActionReducerMapBuilder<ITagSliceState>) => {
    builder.addCase(getTagRolesThunk.fulfilled, (state, action) => {
        return {
            ...state,
            roles: action.payload,
            statuses: {
                ...state.statuses,
                fetch_roles: {
                    state: 'fulfilled',
                }
            }
        }
    })
    builder.addCase(getTagRolesThunk.pending, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_roles: {
                    state: 'pending',
                }
            }
        }
    })
    builder.addCase(getTagRolesThunk.rejected, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_roles: {
                    state: 'rejected',
                    error: action.payload,
                }
            }
        }
    });
    builder.addCase(getTagCategoriesThunk.fulfilled, (state, action) => {
        return {
            ...state,
            categories: action.payload,
            statuses: {
                ...state.statuses,
                fetch_categories: {
                    state: 'fulfilled',
                    error: undefined,
                }
            }
        }
    })
    builder.addCase(getTagCategoriesThunk.pending, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_categories: {
                    state: 'pending',
                }
            }
        }
    })
    builder.addCase(getTagCategoriesThunk.rejected, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_categories: {
                    state: 'rejected',
                    error: action.payload,
                }
            }
        }
    })
}

export default tag_builder;