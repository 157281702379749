import { createSlice } from "@reduxjs/toolkit";
import { IDetailedProductDTO, IProductDTO } from "../../../shared/dto/products"
import { IThunkCallEvent } from "../../../types/redux";
import products_builder from "./builder";
import { ICountedList } from "../../../shared/types/list";

export type IProductSliceState = {
    products: ICountedList<IProductDTO> | null;
    product: IDetailedProductDTO | null;
    statuses: {
        fetch_products: IThunkCallEvent & { query: string };
        fetch_product: IThunkCallEvent & { uuid?: string };
    }
}

const initial_state: IProductSliceState = {
    products: null,
    product: null,
    statuses: {
        fetch_products: {
            state: 'idle',
            query: ''
        },
        fetch_product: {
            state: "idle",
        }
    }
}

const product_slice = createSlice({
    name: "products",
    initialState: initial_state,
    reducers: {},
    extraReducers: products_builder,
})


export default product_slice.reducer;

export const { } = product_slice.actions;