import { createAsyncThunk } from "@reduxjs/toolkit";
import { IListContainerDTO, IListContainerFilterDTO } from "../../../shared/dto/lists";
import BaseError from "../../../shared/errors/base/BaseError";
import api from "../../../api";
import { isOfErrorType } from "../../../shared/type_guards/error";
import ServerError from "../../../shared/errors/ServerError";
import { ICountedList, ISortParams } from "../../../shared/types/list";



export const getListContainersThunk = createAsyncThunk<ICountedList<IListContainerDTO>, { filters: IListContainerFilterDTO, sort?: ISortParams; page?: number; }, { rejectValue: BaseError }>('list_container/getListContainers', async (args, thunkAPI) => {
    try {
        const { filters, sort, page } = args;
        const links_response = await api.LIST_CONTAINER.get_list_containers(filters, sort, page);

        return links_response.data;
    } catch (error) {
        if (isOfErrorType(error)) {
            return thunkAPI.rejectWithValue(error)
        } else {
            return thunkAPI.rejectWithValue(new ServerError())
        }
    }
})

export const getListContainerThunk = createAsyncThunk<IListContainerDTO, { uuid: string }, { rejectValue: BaseError }>('list_container/getListContainer', async (args, thunkAPI) => {
    try {
        const response = await api.LIST_CONTAINER.get_list_container(args.uuid);
        return response.data;
    } catch (error) {
        if (isOfErrorType(error)) {
            return thunkAPI.rejectWithValue(error)
        } else {
            return thunkAPI.rejectWithValue(new ServerError())
        }
    }
})