import config from '../config';
import AuthAPI from './_parts/auth';
import axios, { AxiosInstance } from 'axios';
import TagsAPI from './_parts/tags';
import LinksAPI from './_parts/links';
import SystemAPI from './_parts/system';
import ListContainerAPI from './_parts/list_container';
import ListUpdateAPI from './_parts/list_update';
import FiltersAPI from './_parts/filters';
import CheckoutAPI from './_parts/checkout';
import ProductsAPI from './_parts/products';
import VercelAPI from './_parts/vercel';
import LeadsAPI from './_parts/leads';

class API {
  private axios_instance: AxiosInstance = axios.create();
  private backend_path = config.BACKEND.BACKEND_URL;

  AUTH_API: AuthAPI;
  TAGS_API: TagsAPI;
  LINKS_API: LinksAPI;
  SYS_API: SystemAPI;
  LIST_CONTAINER: ListContainerAPI;
  LIST_UPDATE: ListUpdateAPI;
  FILTERS: FiltersAPI;
  CHECKOUT: CheckoutAPI;
  PRODUCTS: ProductsAPI;
  VERCEL: VercelAPI;
  LEADS: LeadsAPI;

  constructor() {
    this.axios_instance = axios.create();
    this.axios_instance.interceptors.request.use(
      async (config) => {
        const at = localStorage.getItem('at');

        if (at) {
          config.headers.Authorization = `Bearer ${at}`;
        }

        return config;
      },
      (err) => Promise.reject(err)
    );

    this.AUTH_API = new AuthAPI(this.axios_instance, this.backend_path);
    this.TAGS_API = new TagsAPI(this.axios_instance, this.backend_path);
    this.LINKS_API = new LinksAPI(this.axios_instance, this.backend_path);
    this.SYS_API = new SystemAPI(this.axios_instance, this.backend_path);
    this.LIST_CONTAINER = new ListContainerAPI(this.axios_instance, this.backend_path);
    this.LIST_UPDATE = new ListUpdateAPI(this.axios_instance, this.backend_path);
    this.FILTERS = new FiltersAPI(this.axios_instance, this.backend_path);
    this.CHECKOUT = new CheckoutAPI(this.axios_instance, this.backend_path);
    this.PRODUCTS = new ProductsAPI(this.axios_instance, this.backend_path);
    this.VERCEL = new VercelAPI(this.axios_instance, this.backend_path);
    this.LEADS = new LeadsAPI(this.axios_instance, this.backend_path);
  }
}

const api = new API();

export default api;
