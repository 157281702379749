import { ITagCategoryCreateDTO, ITagCategoryDTO, ITagCategoryEditDTO, ITagDTO, } from "../../shared/dto/tags";
import BaseAPI from "../base/base_api";

class TagsAPI extends BaseAPI {
    private paths = {
        CATEGORIES: this.backend + '/api/tag-categories',
        ROLES: this.backend + '/api/tags/roles',
        TAGS: this.backend + "/api/tag-categories/:category_uuid/tags",
        TAGS_BULK_CREATE: this.backend + '/api/tags/bulk-create'
    };

    get_categories() {
        return this.axiosInstance.get(this.paths.CATEGORIES);
    }

    get_roles() {
        return this.axiosInstance.get(this.paths.ROLES);
    }

    create_tag_category(data: ITagCategoryCreateDTO) {
        return this.axiosInstance.post<ITagCategoryDTO>(this.paths.CATEGORIES, data);
    }

    delete_tag_category(uuid: string) {
        return this.axiosInstance.delete(this.paths.CATEGORIES + `/${uuid}`);
    }

    edit_tag_category(uuid: string, tag_category: ITagCategoryEditDTO) {
        return this.axiosInstance.put<ITagCategoryDTO>(this.paths.CATEGORIES + `/${uuid}`, tag_category);
    }

    //Tags
    create_tag(category_uuid: string, tag: string) {
        return this.axiosInstance.post<ITagDTO>(this.paths.TAGS.replace(':category_uuid', category_uuid), {
            value: tag
        })
    }

    edit_tag(category_uuid: string, tag: ITagDTO) {
        return this.axiosInstance.put<ITagDTO>(this.paths.TAGS.replace(':category_uuid', category_uuid) + `/${tag.uuid}`, {
            value: tag.value,
        })
    }

    delete_tag(category_uuid: string, tag_uuid: string) {
        return this.axiosInstance.delete(this.paths.TAGS.replace(':category_uuid', category_uuid) + `/${tag_uuid}`);
    }

    bulk_create_tags(file: File) {
        const form_data = new FormData();

        if (file) {
            form_data.append('tags file', file);
        }

        return this.axiosInstance.post(this.paths.TAGS_BULK_CREATE, form_data, {
            responseType: 'blob'
        })
    }
}

export default TagsAPI;