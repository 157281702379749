import { AxiosInstance } from "axios";

class BaseAPI {
    protected axiosInstance: AxiosInstance;
    protected backend: string;
    constructor(axiosInstance: AxiosInstance, backend: string) {
        this.axiosInstance = axiosInstance;
        this.backend = backend
    }

    protected replace_param(url: string, variables: [string, string][]) {
        let curr_url = url;
        for (let i = 0; i < variables.length; i++) {
            curr_url = curr_url.replace(variables[i][0], variables[i][1]);
        }
        return curr_url;
    }
}

export default BaseAPI;