import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IProductSliceState } from "./slice";
import { getProductThunk, getProductsThunk } from "./thunks";


const products_builder = (builder: ActionReducerMapBuilder<IProductSliceState>) => {
    builder.addCase(getProductsThunk.fulfilled, (state, action) => {
        return {
            ...state,
            products: action.payload.products,
            statuses: {
                ...state.statuses,
                fetch_products: {
                    state: 'fulfilled',
                    query: action.payload.query
                }
            }
        }
    })
    builder.addCase(getProductsThunk.pending, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_products: {
                    ...state.statuses.fetch_products,
                    state: 'pending',

                }
            }
        }
    })
    builder.addCase(getProductsThunk.rejected, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_products: {
                    ...state.statuses.fetch_products,
                    state: 'rejected',
                    error: action.payload,
                }
            }
        }
    });

    builder.addCase(getProductThunk.fulfilled, (state, action) => {
        return {
            ...state,
            product: action.payload,
            statuses: {
                ...state.statuses,
                fetch_product: {
                    state: 'fulfilled',
                    uuid: action.meta.arg.uuid,
                }
            }
        }
    })
    builder.addCase(getProductThunk.pending, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_product: {
                    state: 'pending',
                    uuid: action.meta.arg.uuid,
                }
            }
        }
    })
    builder.addCase(getProductThunk.rejected, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_product: {
                    state: 'rejected',
                    error: action.payload,
                    uuid: action.meta.arg.uuid,
                }
            }
        }
    })
}

export default products_builder;