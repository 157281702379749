import { IVercelStatusResponseDTO } from "../../shared/dto/vercel";
import BaseAPI from "../base/base_api";


class VercelAPI extends BaseAPI {
    private paths = {
        DEPLOY_URL: this.backend + '/api/deploy',
    };

    get_status() {
        return this.axiosInstance.get<IVercelStatusResponseDTO>(this.paths.DEPLOY_URL);
    }

    deploy() {
        return this.axiosInstance.post(this.paths.DEPLOY_URL)
    }


}

export default VercelAPI;