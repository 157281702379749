import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IListContainerSliceState } from "./slice";
import { getListContainerThunk, getListContainersThunk } from "./thunks";

const list_container_builder = (builder: ActionReducerMapBuilder<IListContainerSliceState>) => {
    builder.addCase(getListContainersThunk.fulfilled, (state, action) => {
        return {
            ...state,
            containers: action.payload.rows,
            statuses: {
                ...state.statuses,
                fetch_containers: {
                    state: 'fulfilled',
                    error: undefined,
                    filters: action.meta.arg.filters,
                    sort: action.meta.arg.sort,
                    page: action.meta.arg.page || 1,
                    total: action.payload.count,
                }
            }
        }
    });
    builder.addCase(getListContainersThunk.pending, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_containers: {
                    ...state.statuses.fetch_containers,
                    state: 'pending',
                }
            }
        }
    });
    builder.addCase(getListContainersThunk.rejected, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_containers: {
                    ...state.statuses.fetch_containers,
                    state: 'rejected',
                    error: action.payload,

                }
            }
        }
    });


    //GET LIST CONTAINER
    builder.addCase(getListContainerThunk.fulfilled, (state, action) => {
        return {
            ...state,
            container: action.payload,
            statuses: {
                ...state.statuses,
                fetch_list_container: {
                    state: 'fulfilled',
                    uuid: action.meta.arg.uuid,
                    error: undefined
                }
            }
        }
    })
    builder.addCase(getListContainerThunk.pending, (state, action) => {
        return {
            ...state,
            container: undefined,
            statuses: {
                ...state.statuses,
                fetch_list_container: {
                    state: 'pending',
                    uuid: action.meta.arg.uuid,
                    error: undefined
                }
            }
        }
    })
    builder.addCase(getListContainerThunk.rejected, (state, action) => {
        return {
            ...state,
            container: null,
            statuses: {
                ...state.statuses,
                fetch_list_container: {
                    state: 'rejected',
                    uuid: action.meta.arg.uuid,
                    error: action.payload
                }
            }
        }
    })
}

export default list_container_builder;