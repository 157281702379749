import { IListUpdateDTO } from "../../shared/dto/lists";
import BaseAPI from "../base/base_api";


class ListUpdateAPI extends BaseAPI {
    private paths = {
        LIST_UPDATE: this.backend + '/api/list-container/:container_uuid/list-update'
    };


    // get_list_info(list_container_uuid: string, file?: File) {
    //     const form_data = new FormData();

    //     if (file) {
    //         form_data.append('list update', file);
    //     }


    //     return this.axiosInstance.post<IFileAnalysis>(this.replace_param(this.paths.LIST_UPDATE, [[':container_uuid', list_container_uuid]]) + '/info', form_data)
    // }

    update_list(list_container_uuid: string, file?: File) {
        const form_data = new FormData();

        if (file) {
            form_data.append('list update', file);
        }


        return this.axiosInstance.post<IListUpdateDTO>(this.replace_param(this.paths.LIST_UPDATE, [[':container_uuid', list_container_uuid]]), form_data)
    }

    get_list_updates(list_container_uuid: string) {
        return this.axiosInstance.get<IListUpdateDTO[]>(this.replace_param(this.paths.LIST_UPDATE, [[':container_uuid', list_container_uuid]]))
    }

    get_list_update(list_container_uuid: string, update_uuid: string) {
        return this.axiosInstance.get<IListUpdateDTO>(this.replace_param(this.paths.LIST_UPDATE, [[':container_uuid', list_container_uuid]]) + `/${update_uuid}`)
    }

    download_list_update(list_container_uuid: string, update_uuid: string) {
        const link = document.createElement('a');
        link.href = this.replace_param(this.paths.LIST_UPDATE, [[':container_uuid', list_container_uuid]]) + `/${update_uuid}/download?token=Bearer ${localStorage.getItem('at')}`

        link.click();

        link.remove();
    }
}

export default ListUpdateAPI;