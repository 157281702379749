import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IFilterSliceState } from "./slice";
import { getFiltersThunk } from "./thunks";

const filters_builder = (builder: ActionReducerMapBuilder<IFilterSliceState>) => {
    builder.addCase(getFiltersThunk.fulfilled, (state, action) => {
        return {
            ...state,
            filters: action.payload,
            statuses: {
                ...state.statuses,
                fetch_filters: {
                    state: 'fulfilled',
                }
            }
        }
    })
    builder.addCase(getFiltersThunk.pending, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_filters: {
                    state: 'pending',
                }
            }
        }
    })
    builder.addCase(getFiltersThunk.rejected, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_filters: {
                    state: 'rejected',
                    error: action.payload
                }
            }
        }
    })
}

export default filters_builder;