import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ILinksSliceState } from "./slice";
import { getLinksThunk } from "./thunks";

const links_builder = (builder: ActionReducerMapBuilder<ILinksSliceState>) => {
    builder.addCase(getLinksThunk.fulfilled, (state, action) => {
        return {
            ...state,
            links: action.payload,
            statuses: {
                ...state.statuses,
                fetch_links: {
                    state: 'fulfilled',
                    error: undefined,
                }
            }
        }
    });
    builder.addCase(getLinksThunk.pending, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_links: {
                    state: 'pending',
                }
            }
        }
    });
    builder.addCase(getLinksThunk.rejected, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_links: {
                    state: 'rejected',
                    error: action.payload,
                }
            }
        }
    });
}

export default links_builder;