import { IEmailNewsLetterDTO } from '../../shared/dto/email';
import { ICountedList } from '../../shared/types/list';
import BaseAPI from '../base/base_api';

class LeadsAPI extends BaseAPI {
  private paths = {
    NEWSLETTER_PATH: this.backend + '/api/newsletter',
  };

  getFreeEmailLeads(page: number = 1) {
    return this.axiosInstance.get<ICountedList<IEmailNewsLetterDTO>>(this.paths.NEWSLETTER_PATH, {
      params: {
        page,
      },
    });
  }

  async downloadEmailLeads() {
    const response = await this.axiosInstance.get(this.paths.NEWSLETTER_PATH + '/download', {
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const ms = new Date().getTime();
    const fileName = 'registered_emails_' + ms + '.csv';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  }
}

export default LeadsAPI;
