import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IUserSliceState } from './slice';
import { getUserThunk } from './thunks';


const user_builder = (builder: ActionReducerMapBuilder<IUserSliceState>) => {
    builder.addCase(getUserThunk.fulfilled, (state, action) => {
        return {
            ...state,
            user: action.payload,
            statuses: {
                ...state.statuses,
                fetch_user: {
                    state: 'fulfilled',
                    error: undefined,
                }
            }
        }
    })
    builder.addCase(getUserThunk.pending, (state, action) => {
        return {
            ...state,
            statuses: {
                ...state.statuses,
                fetch_user: {
                    state: 'pending',
                    error: undefined,
                }
            }
        }
    })
    builder.addCase(getUserThunk.rejected, (state, action) => {
        return {
            ...state,
            user: undefined,
            statuses: {
                ...state.statuses,
                fetch_user: {
                    state: 'rejected',
                    error: action.payload,
                }
            }
        }
    })
};

export default user_builder;
