import { ErrorNamesEnum } from '../../enums/error';
import BaseErrorItem from './BaseErrorItem';

class BaseError extends Error {
  code: number;
  origin = 'STARTUP_DATA';
  items?: BaseErrorItem[];
  constructor(name: ErrorNamesEnum, error_code: number, message?: string) {
    super(message);
    this.name = name;
    this.code = error_code;
  }
}

export default BaseError;
