import { ErrorNamesEnum } from "../enums/error";
import BaseError from "../errors/base/BaseError";

export function isBaseError(value: unknown): value is BaseError {
    return typeof value === 'object' && value !== null && value.hasOwnProperty('origin')
}

export function isOfErrorType(value: unknown): value is BaseError {
    return typeof value === 'object' && value !== null && value.hasOwnProperty('name') && Object.values(ErrorNamesEnum).includes((value as any).name)
}

