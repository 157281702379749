import { HelmetProvider } from 'react-helmet-async';
import AppRouter from './routes';
import { ToastContainer } from 'react-toastify';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux/store';
import ErrorBoundary from './components_util/util/ErrorBoundary';

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <HelmetProvider>
          <ReduxProvider store={store}>
            <ToastContainer position="bottom-right" />
            <AppRouter />
          </ReduxProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
