import { ErrorCodesEnum, ErrorNamesEnum } from '../enums/error';
import BaseError from './base/BaseError';

class ServerError extends BaseError {
  constructor(message?: string) {
    super(ErrorNamesEnum.SERVER_ERROR, ErrorCodesEnum.SERVER_ERROR, message || 'Unexpected error');
  }
}

export default ServerError;
