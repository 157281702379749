import ConfigurationError from '../../errors/ConfigurationError';

export default function get_string_variable(var_name: string) {
  const var_value = process.env[var_name];

  if (var_value) {
    return var_value;
  } else {
    throw new ConfigurationError(`${var_name} is not defined!`);
  }
}
