import { ILinkCreateDTO, ILinkDTO, ILinkEditDTO } from "../../shared/dto/links";
import BaseAPI from "../base/base_api";

class LinksAPI extends BaseAPI {
    private paths = {
        LINKS: this.backend + '/api/links'
    };

    get_links() {
        return this.axiosInstance.get<ILinkDTO[]>(this.paths.LINKS);
    }

    create_link(link: ILinkCreateDTO) {
        return this.axiosInstance.post<ILinkDTO>(this.paths.LINKS, link)
    }

    update_link(uuid: string, link: ILinkEditDTO) {
        return this.axiosInstance.put<ILinkDTO>(this.paths.LINKS + `/${uuid}`, link)
    }

    delete_link(uuid: string) {
        return this.axiosInstance.delete(this.paths.LINKS + `/${uuid}`);
    }
}

export default LinksAPI;