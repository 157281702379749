import React, { PropsWithChildren } from 'react';
import { Container } from 'react-bootstrap';

interface ErrorBoundaryProps extends PropsWithChildren {}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    //TODO: Log this in backend
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <h1>Oops! Something went wrong!</h1>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
