import { IUserDTO, IUserLoginDTO, IUserLoginResponseDTO } from "../../shared/dto/user";
import BaseAPI from "../base/base_api";


class AuthAPI extends BaseAPI {
    private paths = {
        AUTH: this.backend + '/api/auth'
    };

    login(user: IUserLoginDTO) {
        return this.axiosInstance.post<IUserLoginResponseDTO>(this.paths.AUTH, user)
    }

    getUser() {
        return this.axiosInstance.get<IUserDTO>(this.paths.AUTH)
    }
}

export default AuthAPI;