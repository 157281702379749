import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IListContainerDTO, IListContainerFilterDTO } from "../../../shared/dto/lists"
import { IThunkCallEvent } from "../../../types/redux";
import list_container_builder from "./builder";
import { ISortParams } from "../../../shared/types/list";

export type IListContainerSliceState = {
    containers: IListContainerDTO[] | null;
    container?: IListContainerDTO | null;
    statuses: {
        fetch_containers: IThunkCallEvent & { filters: IListContainerFilterDTO, page: number; sort?: ISortParams; total?: number; };
        fetch_list_container: IThunkCallEvent & { uuid?: string }
    }
}

const initial_state: IListContainerSliceState = {
    containers: null,
    statuses: {
        fetch_containers: {
            state: 'idle',
            filters: {},
            page: 1
        },
        fetch_list_container: {
            state: 'idle'
        }
    }
}

const container_slice = createSlice({
    name: "list_container",
    initialState: initial_state,
    reducers: {
        addListContainer(state, action: PayloadAction<IListContainerDTO>) {
            return {
                ...state,
                containers: state.containers ? [action.payload, ...state.containers] : state.containers
            }
        },
        removeListContainer(state, action: PayloadAction<string>) {
            return {
                ...state,
                containers: state.containers ? state.containers.filter(v => v.uuid !== action.payload) : state.containers
            }
        },
        updateListContainer(state, action: PayloadAction<IListContainerDTO>) {
            const container_uuid = action.payload.uuid;
            return {
                ...state,
                container: state.container && state.container.uuid === container_uuid ? action.payload : state.container,
                containers: state.containers ? state.containers.map(v => {
                    if (v.uuid === container_uuid) {
                        return action.payload
                    } else {
                        return v;
                    }
                }) : state.containers
            }
        },
        updateListContainerPublishState(state, action: PayloadAction<Pick<IListContainerDTO, 'published' | "uuid">>) {
            const { uuid, published } = action.payload;
            return {
                ...state,
                containers: state.containers ? state.containers.map(c => {
                    if (c.uuid === uuid) {
                        return {
                            ...c,
                            published
                        }
                    } else {
                        return c;
                    }
                }) : state.containers,
            }
        }
    },
    extraReducers: list_container_builder,
})

export default container_slice.reducer;
export const { addListContainer, removeListContainer, updateListContainer, updateListContainerPublishState } = container_slice.actions;
