import { IConfig } from "../types/configuration";
import get_backend_config from "./functions/get_backend_config";
import get_env from "./functions/get_env";

const env = get_env();

const config: IConfig = {
    ENV: env,
    BACKEND: get_backend_config(),
};


export default config;