import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../api";
import { IUserDTO } from "../../../shared/dto/user";
import BaseError from "../../../shared/errors/base/BaseError";
import { isOfErrorType } from "../../../shared/type_guards/error";
import ServerError from "../../../shared/errors/ServerError";



export const getUserThunk = createAsyncThunk<IUserDTO, {}, { rejectValue: BaseError }>('user/getUserInfo', async (args, thunkAPI) => {
    try {
        const user_response = await API.AUTH_API.getUser();
        return user_response.data;
    } catch (error) {
        if (isOfErrorType(error)) {
            return thunkAPI.rejectWithValue(error)
        } else {
            return thunkAPI.rejectWithValue(new ServerError())
        }
    }
})