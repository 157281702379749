import { createSlice } from "@reduxjs/toolkit";
import { IThunkCallEvent } from "../../../types/redux";
import system_builder from "./builder";

export type ISystemSliceState = {
    default_list_image: string | null;
    statuses: {
        fetch_default_image: IThunkCallEvent;
    }
}

const initial_state: ISystemSliceState = {
    default_list_image: null,
    statuses: {
        fetch_default_image: {
            state: 'idle',
        }
    }
}

const system_slice = createSlice({
    name: 'system',
    initialState: initial_state,
    reducers: {},
    extraReducers: system_builder,
})

export default system_slice.reducer;
export const { } = system_slice.actions;