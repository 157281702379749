import { ErrorNamesEnum } from '../enums/error';
import BaseError from './base/BaseError';

class ConfigurationError extends BaseError {
  constructor(message: string) {
    super(ErrorNamesEnum.CONFIGURATION_ERROR, 0, `[CONFIGURATION_ERR]: ${message}`);
  }
}

export default ConfigurationError;
