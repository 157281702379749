import ConfigurationError from "../../shared/errors/ConfigurationError";
import { is_env } from "../../shared/type_guards/application";
import get_string_variable from "../../shared/util/config/get_string_variable";

export default function get_env() {
    const val = get_string_variable('REACT_APP_ENV');

    if (is_env(val)) {
        return val;
    } else {
        throw new ConfigurationError('Invalid REACT_APP_ENV value');
    }
}