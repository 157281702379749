import BaseAPI from "../base/base_api";

class SystemAPI extends BaseAPI {
    private paths = {
        SYSTEM: this.backend + '/api/system'
    };

    get_default_list_image() {
        return this.axiosInstance.get<string>(this.paths.SYSTEM + '/list-image');
    }

}

export default SystemAPI;