import { IListContainerCreateDTO, IListContainerDTO, IListContainerFilterDTO, IListContainerUpdateDTO } from "../../shared/dto/lists";
import { ICountedList, ISortParams } from "../../shared/types/list";
import BaseAPI from "../base/base_api";


class ListContainerAPI extends BaseAPI {
    private paths = {
        LIST_CONTAINER: this.backend + '/api/list-container',
        BULK_CREATE_CONTAINER: this.backend + "/api/list-container/bulk-create",
        LIST_CONTAINER_ITEM: this.backend + '/api/list-container/:list_container_uuid',
        LIST_CONTAINER_DUPLICATE: this.backend + '/api/list-container/:list_container_uuid/duplicate'
    };

    create_list_container(list: IListContainerCreateDTO, file?: File) {
        const form_data = new FormData();

        if (file) {
            form_data.append('list-container-image', file);
        }

        form_data.append('name', list.name);
        form_data.append('tags', JSON.stringify(list.tags));
        form_data.append('preview_link', list.preview_link);
        form_data.append('description', list.description);
        form_data.append('role', list.role);
        form_data.append('featured', String(list.featured));
        if (list.note) {
            form_data.append('note', list.note);
        }
        if (list.weight) {
            form_data.append('weight', list.weight.toString());
        }
        return this.axiosInstance.post<IListContainerDTO>(this.paths.LIST_CONTAINER, form_data)
    }

    bulk_create_list_containers(file: File) {
        const form_data = new FormData();

        if (file) {
            form_data.append('containers file', file);
        }

        return this.axiosInstance.post(this.paths.BULK_CREATE_CONTAINER, form_data, {
            responseType: 'blob'
        })
    }

    get_list_containers(filters: IListContainerFilterDTO, sort?: ISortParams, page?: number) {
        return this.axiosInstance.get<ICountedList<IListContainerDTO>>(this.paths.LIST_CONTAINER, {
            params: {
                ...filters,
                ...(sort || {}),
                page
            }
        });
    }

    get_list_container(uuid: string) {
        return this.axiosInstance.get<IListContainerDTO>(this.paths.LIST_CONTAINER + `/${uuid}`);
    }

    delete_list_container(uuid: string) {
        return this.axiosInstance.delete<{ message: string }>(this.paths.LIST_CONTAINER + `/${uuid}`)
    }

    update_list_container(container_uuid: string, list: IListContainerCreateDTO, file?: File) {
        const form_data = new FormData();

        if (file) {
            form_data.append('list-container-image', file);
        }

        form_data.append('name', list.name);
        form_data.append('tags', JSON.stringify(list.tags));
        form_data.append('preview_link', list.preview_link);
        form_data.append('description', list.description);
        form_data.append('role', list.role)
        form_data.append('featured', String(list.featured));
        if (list.note) {
            form_data.append('note', list.note);
        }
        if (list.weight) {
            form_data.append('weight', list.weight.toString());
        }
        return this.axiosInstance.put<IListContainerDTO>(this.paths.LIST_CONTAINER + `/${container_uuid}`, form_data)
    }

    partial_update_list_container(container_uuid: string, update: IListContainerUpdateDTO) {
        return this.axiosInstance.patch<IListContainerUpdateDTO>(this.replace_param(this.paths.LIST_CONTAINER_ITEM, [[':list_container_uuid', container_uuid]]), update)
    }

    duplicate_list_container(container_uuid: string) {
        return this.axiosInstance.post<IListContainerDTO>(this.replace_param(this.paths.LIST_CONTAINER_DUPLICATE, [[':list_container_uuid', container_uuid]]))
    }

}

export default ListContainerAPI;