import { createSlice } from '@reduxjs/toolkit';
import { IEmailNewsLetterDTO } from '../../../shared/dto/email';
import { IThunkCallEvent } from '../../../types/redux';
import leads_builder from './builder';

export type ILeadsSliceState = {
  freeListLeads: IEmailNewsLetterDTO[] | null;
  statuses: {
    fetchFreeListLeads: IThunkCallEvent & { page: number; total: number };
  };
};

const initial_state: ILeadsSliceState = {
  freeListLeads: null,
  statuses: {
    fetchFreeListLeads: {
      state: 'idle',
      page: 1,
      total: 0,
    },
  },
};

const leads_slice = createSlice({
  name: 'leads',
  initialState: initial_state,
  reducers: {},
  extraReducers: leads_builder,
});

export default leads_slice.reducer;
export const {} = leads_slice.actions;
