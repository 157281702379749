export enum ErrorNamesEnum {
  CONFIGURATION_ERROR = 'CONFIGURATION_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
  ENTITY_ERROR = 'ENTITY_ERROR',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
}

export enum ErrorCodesEnum {
  VALIDATION_ERROR = 403,
  SERVER_ERROR = 500,
  ENTITY_ERROR = 400,
  AUTHENTICATION_ERROR = 401,
}
