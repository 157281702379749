import { Reducer, combineReducers, configureStore, createAction } from '@reduxjs/toolkit';
import config from '../config';
import { IStore } from '../types/redux';
import user_slice from './slices/user/slice';
import tags_slice from './slices/tags/slice';
import link_slice from './slices/links/slice';
import system_slice from './slices/system/slice';
import list_container_slice from './slices/list_containers/slice';
import filters_slice from './slices/filters/slice';
import list_update_slice from './slices/list_updates/slice';
import products_slice from './slices/products/slice';
import leads_slice from './slices/leads/slice';

const ENV = config.ENV;

const combined_reducer = combineReducers<IStore>({
  user: user_slice,
  tags: tags_slice,
  links: link_slice,
  system: system_slice,
  list_container: list_container_slice,
  filters: filters_slice,
  list_update: list_update_slice,
  products: products_slice,
  leads: leads_slice,
});

const root_reducer: Reducer = (state, action) => {
  if (action.type === 'app/logout') {
    state = undefined;
  }
  return combined_reducer(state, action);
};

export const store = configureStore({
  reducer: root_reducer,
  devTools: ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const LogoutAction = createAction('app/logout');

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
