import { IFilterDTO } from "../../shared/dto/filters";
import BaseAPI from "../base/base_api";


class FiltersAPI extends BaseAPI {
    private paths = {
        FILTERS: this.backend + '/api/filters'
    };

    get_filters(role?: string) {
        return this.axiosInstance.get<IFilterDTO[]>(this.paths.FILTERS, {
            params: {
                role
            }
        });
    }

}

export default FiltersAPI;