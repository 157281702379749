import { createSlice } from "@reduxjs/toolkit";
import { IFilterDTO } from "../../../shared/dto/filters"
import { IThunkCallEvent } from "../../../types/redux";
import filters_builder from "./builder";

export type IFilterSliceState = {
    filters: IFilterDTO[] | null;
    statuses: {
        fetch_filters: IThunkCallEvent;
    }
}

const initial_state: IFilterSliceState = {
    filters: null,
    statuses: {
        fetch_filters: {
            state: 'idle'
        },
    }
}

const filters_slice = createSlice({
    name: "filters",
    initialState: initial_state,
    reducers: {},
    extraReducers: filters_builder,
})

export default filters_slice.reducer;

export const { } = filters_slice.actions;