import React from 'react';
import { IUserDTO } from '../../shared/dto/user';
import { IStore, IThunkCallEvent } from '../../types/redux';
import { MapDispatchToProps, MapStateToProps, connect } from 'react-redux';
import { getUserThunk } from '../../redux/slices/user/thunks';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRoutingProps {}

interface ProtectedRoutingState {}

type IMapState = {
  user?: IUserDTO | null;
  user_call_status: IThunkCallEvent;
};

type IMapDispatch = {
  getUser(): void;
};

const mapState: MapStateToProps<IMapState, ProtectedRoutingProps, IStore> = (state, props) => {
  return {
    user: state.user.user,
    user_call_status: state.user.statuses.fetch_user,
  };
};

const mapDispatch: MapDispatchToProps<IMapDispatch, ProtectedRoutingProps> = (dispatch) => {
  return {
    getUser: () => dispatch(getUserThunk({}) as any),
  };
};

const connector = connect(mapState, mapDispatch);

type IConnectedProps = IMapState & IMapDispatch & ProtectedRoutingProps;
class ProtectedRouting extends React.Component<IConnectedProps, ProtectedRoutingState> {
  constructor(props: IConnectedProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    const at = localStorage.getItem('at');
    if (at) {
      if (this.props.user_call_status.state === 'idle') {
        this.props.getUser();
      }
    }
  }

  render() {
    const at = localStorage.getItem('at');

    if (at) {
      if (this.props.user) {
        return <Outlet />;
      } else {
        switch (this.props.user_call_status.state) {
          case 'idle':
            return (
              <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Getting user ...
              </div>
            );
          case 'pending':
            return (
              <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Getting user ...
              </div>
            );
          case 'rejected':
            localStorage.removeItem('at');
            return <Navigate to="/login" replace />;
          default:
            return <Navigate to="/login" replace />;
        }
      }
    } else {
      return <Navigate to="/login" replace />;
    }
  }
}

export default connector(ProtectedRouting);
