import { createAsyncThunk } from "@reduxjs/toolkit";
import { IDetailedProductDTO, IProductDTO } from "../../../shared/dto/products";
import BaseError from "../../../shared/errors/base/BaseError";
import api from "../../../api";
import { isOfErrorType } from "../../../shared/type_guards/error";
import ServerError from "../../../shared/errors/ServerError";
import { ICountedList } from "../../../shared/types/list";

export const getProductsThunk = createAsyncThunk<{ products: ICountedList<IProductDTO>, query: string }, { page?: number, filters?: { [key: string]: string }, query: string }, { rejectValue: BaseError }>('products/getProducts', async (args, thunkAPI) => {
    try {
        const { filters, page, query } = args

        const links_response = await api.PRODUCTS.getProducts(filters, page);

        return { products: links_response.data, query };
    } catch (error) {
        if (isOfErrorType(error)) {
            return thunkAPI.rejectWithValue(error)
        } else {
            return thunkAPI.rejectWithValue(new ServerError())
        }
    }
})

export const getProductThunk = createAsyncThunk<IDetailedProductDTO, { uuid: string }, { rejectValue: BaseError }>('products/getProduct', async (args, thunkAPI) => {
    try {
        const { uuid } = args;

        const response = await api.PRODUCTS.getProduct(uuid);

        return response.data;
    } catch (error) {
        if (isOfErrorType(error)) {
            return thunkAPI.rejectWithValue(error)
        } else {
            return thunkAPI.rejectWithValue(new ServerError())
        }
    }
})